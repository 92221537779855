<template lang='pug'>
.app
  .loading(v-if='loading > 0')
    v-progress-circular(indeterminate color='primary' size='70' width='5')
  router-view
</template>

<script>

export default {
  name: 'App',
  computed: {
    loading() {
      return this.$store.state.loading;
    }
  }
};
</script>
<style lang="scss">
html, body {
    font-family: "Open Sans", sans-serif;
}
.loading {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(255,255,255, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>